<template>
  <v-navigation-drawer
  v-model="drawer"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
    class="draw-bg"
  >
    
    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item>
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <v-img src="https://www.bloggang.com/data/b/bigstory/picture/1449748275.gif" rounded />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ user.personnel_etc_id_card }}</v-list-item-title>
          <v-list-item-title>{{ user.personnel_etc_title }}{{ user.personnel_etc_fristname }} {{ user.personnel_etc_lastname }} </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>      


    <v-divider class="mb-2" />

    <v-list expand nav>
      <div />

      <v-list-item to="/UserProfile">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>

          <v-list-item-title>ข้อมูลผู้ใช้งานระบบ</v-list-item-title>
        </v-list-item>

      <v-list-group
        v-for="item in itemsGeneral"
        :key="item.title"
        v-model="item.icon"
        :prepend-icon="item.action"
        
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="child in item.itemsGeneral" :key="child.title" :to="child.to">
           
          <v-list-item-content>
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-text="child.icon"></v-icon>
          </v-list-item-icon>
         
        </v-list-item>
      </v-list-group>   

      <v-list-item to="/personnel_work_history">
          <v-list-item-icon>
            <v-icon>mdi-access-point-network</v-icon>
          </v-list-item-icon>

          <v-list-item-title>ข้อมูลประวัติการรับราชการ</v-list-item-title>
        </v-list-item>


         <v-list-item to="/assistant_teacher" v-if="user.position_name==='ครูผู้ช่วย'">
          <v-list-item-icon>
            <v-icon>mdi-account-arrow-right</v-icon>
          </v-list-item-icon>

          <v-list-item-title>การประเมินครูผู้ช่วย</v-list-item-title>
        </v-list-item>

      <div />
    </v-list>    
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";

export default {
  name: "DashboarduserDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    ApiKey: 'HRvec2021',
    user_data: {},
    user: {},
    loginuser: JSON.parse(sessionStorage.getItem("user")) || 0,
    itemsGeneral: [
    {
        action: "mdi-apps",
        itemsGeneral: [   
      {
        title: "1.ความรู้ คุณวุฒิการศึกษา",
        icon: "mdi-school",
        to: "/personnel_education",
      },
      {
        title: "2.การปฏิบัติหน้าที่",
        icon: "mdi-account-network",
        to: "/personnel_duty",
      },
      {
        title: "3.อายุราชการ",
        icon: "mdi-account-star",
        to: "/service_age",
      },     
      {
        title: "4.ผลการปฏิบัติงาน",
        icon: "mdi-trophy-award",
        to: "/personnel_award",
      },
      {
        title: "5.ระยะเวลาการปฏิบัติหน้าที่",
        icon: "mdi-timetable",
        to: "/period_current",
      },
      {
        title: "6.การรักษาวินัยฯ",
        icon: "mdi-alarm-light",
        to: "/personnel_discipline",
      },
      {
        title: "7.การพัฒนาตนเอง/วิชาชีพ",
        icon: "mdi-airplane",
        to: "/personnel_experience",
      },    
      {
        title: "8.สภาพความยากลำบาก",
        icon: "mdi-airplane",
        to: "/work_difficulty",
      },      
      ],
        title: "องค์ประกอบ",
      },     
    ],
  }),

  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.itemsGeneral.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: "",
      };
    },
  },
  async mounted() {
      let result
      let userSession = JSON.parse(sessionStorage.getItem('user')) || 0
     result = await this.$http.post('personnel_etc.php', {
         ApiKey: this.ApiKey, 
        personnel_etc_id_card: userSession.personnel_etc_id_card       
      })
      this.user = result.data     
    },

  methods: {    
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
    logout() {
      sessionStorage.clear();
      this.$router.push("/");
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
<style>
.draw-bg {
  background-color: #787777;
  border-color: #ffffff;
}
</style>
